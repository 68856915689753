@font-face {
  font-family: "light";
  src: url("../Font/Raleway-Light.ttf");
}

@font-face {
  font-family: "regular";
  src: url("../Font/Raleway-Regular.ttf");
}

@font-face {
  font-family: "bold";
  src: url("../Font/Raleway-Bold.ttf");
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.light {
  font-family: "light";
}

.bold {
  font-family: "bold";
}

.regular {
  font-family: "regular";
}

body {
  font-family: "regular" !important;
}

div.loaderContainer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10000000;
  position: fixed;
  top: 0;
  left: 0;
}

img.loaderContainer {
  width: 100px;
}

.getLoader.true {
  display: flex !important;
}

div.notification {
  width: 97%;
  border-radius: 10px;
  padding: 8px 20px;
}

div.notification.error {
  background-color: rgba(255, 65, 65, 0.95);
  color: #fff;
}

div.notification.noNotify {
  background-color: rgba(65, 157, 255, 0.95);
  color: #fff;
}

div.notification.success {
  background-color: rgba(64, 144, 94, 0.95);
  color: #fff;
}

div.notification.warning {
  background-color: rgba(255, 206, 81, 0.95);
}

.lowLimit {
  color: #f9a825;
}
.mediumLimit {
  color: #ef6c00;
}
.highLimit {
  color: #bf360c;
}

button.notification {
  width: 20px;
  height: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.notification > img {
  width: 100%;
}

.subHover:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* REACT SELECT */
div.css-yk16xz-control,
div.css-1pahdxg-control,
div.css-1fhf3k1-control {
  border-radius: 10px;
  min-height: 48px;
  border: none;
  margin-top: 5px;
}

span.css-1okebmr-indicatorSeparator {
  display: none;
}

div.css-107lb6w-singleValue {
  color: #333;
}

div.css-1fhf3k1-control {
  background-color: #e9ecef;
  height: 48px;
}

div.container-full {
  width: 100%;
  margin: 0;
  padding: 0;
}

.rootBg {
  background-color: #f4f7f9;
}

.no-deco {
  text-decoration: none !important;
}

.hoverBack:hover {
  background-color: #e7f1f6;
}

div.pageContainer {
  padding-left: 80px;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 80px;
}

button.btnIcon,
label.btnIcon {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: 0 0 0 #000 !important;
}

button.btnIcon img,
label.btnIcon img {
  height: 25px;
}

div.modal-body,
div.modal-content {
  background-color: #f5f5f5 !important;
  border-radius: 30px !important;
}

div.modal-body.minH {
  min-height: 350px;
}

.btnBlu {
  background-color: #008bdb;
  color: white;
  border-radius: 10px;
}

.btnTransaparent {
  background-color: transparent;
  color: #333;
}

.btnTransaparent.btnXl {
  height: 60px;
  font-size: 1.2em;
}

.btnXl:not(.btnTransaparent) {
  height: 50px;
  min-width: 180px;
  font-size: 1.2em;
}

.btnL:not(.btnTransaparent) {
  height: 48px;
  min-width: 80px;
  font-size: 1.1em;
}

span.notificationEmails {
  background-color: #dfdfdf;
  padding: 2px 10px;
  border-radius: 10px;
}

span.assignmentErrorEmails {
  background-color: #dfdfdf;
  padding: 2px 10px;
  border-radius: 10px;
}

.light-grey {
  color: #cdcdcd;
}

.btn-link {
  color: #008bdb !important;
}

.text-blu {
  color: #008bdb !important;
}

.btn.cancel {
  font-family: "regular";
}

input.inputLuxo {
  border-radius: 10px;
  min-height: 48px;
  border: none;
  margin-top: 5px;
}
input.inputLuxo.inputGrey {
  border: 1px solid rgb(189, 189, 189) !important;
}

input.inputLuxo.minWidth {
  min-width: 500px;
}

input.inputLuxo:disabled {
  background-color: #dfdfdf;
}

div.backdropConfirm {
  z-index: 1050;
}

div.dialogConfirm {
  min-width: 600px;
}

label.validation {
  color: red;
  font-size: 0.9em;
}

div.orderContainer {
  background-color: white !important;
  z-index: 1000;
  top: 100%;
  right: 0;
  border-radius: 20px;
  box-shadow: 3px 3px 10px -3px rgba(0, 0, 0, 0.3);
}

div.importingContainer {
  background-color: white !important;
  z-index: 1000;
  top: 180%;
  width: 400px;
  min-height: 100px;
  max-height: 500px;
  overflow-y: auto;
  right: 23px;
  border-radius: 20px;
  box-shadow: 3px 3px 10px -3px rgba(0, 0, 0, 0.3);
}

.hidden {
  visibility: hidden;
}

div.version {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

div.versionCollaudo {
  position: fixed;
  background-color: #b00020;
  color: white;
  bottom: 0;
  left: 0;
  z-index: 1001;
  width: 100vw;
  padding:5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.columnsList {
  max-height: 450px;
  width: 100%;
  overflow-y: auto;
}
div.columnsList div:not(.gradient):hover {
  background-color: #e7f1f6;
}

.error,
.Error,
.Failed,
.Deleted {
  color: #b00020;
}

.completed,
.Success {
  color: #388e3c;
}

.Warning {
  color: goldenrod;
}

.btnIcon.btnOrder.active {
  border: 2px solid #555 !important;
  border-radius: 8px;
}

button.btnTab {
  font-family: "regular";
  border-radius: 0;
  border-bottom: 2px solid #888;
  color: #888;
  padding: 5px 35px;
}

button.btnTab.active {
  font-family: "bold";
  border-bottom: 2px solid #008bdb;
  color: #008bdb;
}

button.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  outline: none;
}
