.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

div.dropDownCustom {
  display: none;
  position: absolute;
  left: 108%;
  top: -10px;
  z-index: 100;
  flex-direction: column !important;
}

div.dropDownCustom.active {
  display: flex;
}

div.navLink {
  width: 80px;
  height: 80px;
  cursor: pointer;
}

div.navLink.active {
  background-color: #e7f1f6;
}

div.navLink.active span {
  color: #008bdb;
  font-family: 'bold';
}

div.sideBarContainer{
  max-width: 80px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}
